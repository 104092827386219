html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: "asd", sans-serif;
    background-color: var(--color-white);
}

body::-webkit-scrollbar {
    width: 8px;
    background: rgb(224, 224, 224);
}

body::-webkit-scrollbar-thumb {
    border: 2px solid rgb(224, 224, 224);
    border-radius: 4px;
    background: linear-gradient(#b7ddf7, #1b69c2);
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
}

.is-outer {
    position: relative !important; // stylelint-disable-line declaration-no-important
    left: -9999px !important; // stylelint-disable-line declaration-no-important
}

.site-container {
    overflow: hidden;
}

.is-hidden {
    display: none !important; // stylelint-disable-line declaration-no-important
}

.for-mobile {
    display: none;
    @include mobile {
        display: block;
    }
}

.error {
    border: 2px #ff4019 solid !important; // stylelint-disable-line declaration-no-important
}

.btn-reset {
    border: none;
    background: transparent;
    cursor: pointer;
}

.container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}
